import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [Open, setOpen] = useState(false);
    const closeMenu = () => { setOpen(false); };
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    return (
        <>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="modal" id="mypopUpForm">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 style={{ color: '#000' }}><i className="fa fa-desktop"> </i> &nbsp;Important Notice from
                                            <span style={{ color: '#f00' }}> Green Ville Projects PVT. Ltd. !</span></h5>
                                        <button style={{color:"#000000"}} className="close" data-dismiss="modal"><b style={{color:"#000000"}}>X</b></button>
                                    </div>
                                    <div className="modal-body">

                                        <section id="features" className="features">
                                            <div className="" data-aos="fade-up">
                                                <div className="row">

                                                    <div className="col-lg-12" data-aos="fade-left" data-aos-delay={100}>
                                                        <p>It has come to our attention that some third-party entities are promoting Green Ville Projects' ventures under different investment plans, including fractional ownership and other schemes.</p>
                                                        <div className="icon-box mt-2 mt-lg-0" data-aos="zoom-in" data-aos-delay={150}>
                                                            <i className="bx bx-receipt" />
                                                            <p>⚠️ We want to clarify that Green Ville Projects PVT Ltd has NOT partnered with any company or individual for such promotions.</p>
                                                        </div>
                                                        <div className="icon-box mt-2" data-aos="zoom-in" data-aos-delay={150}>
                                                            <i className="bx bx-cube-alt" />
                                                            <p>✅ Our projects and investment options are handled exclusively by Green Ville Projects, and we take NO responsibility for any misleading claims made by unauthorized sources.</p>
                                                        </div>
                                                        <div className="icon-box mt-2" data-aos="zoom-in" data-aos-delay={150}>
                                                            <i className="bx bx-images" />
                                                            <p>💬 For genuine and verified information, always reach out to us directly through our official communication channels.</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                                                    <i className="bx bx-shield" />
                                                    <p>📞 Contact us at:  <a href="tel:+914029391369"><b> +91 4029391369 </b> </a></p>
                                                    <p>🌐 Website: <a href='https://greenvilleprojects.com/' target="_blank"> <b>www.greenvilleprojects.com</b></a></p>
                                                </div>

                                                <br />
                                                <h5 style={{ color: '#000' }}><i className=""> </i> &nbsp;
                                                    <span style={{ color: '#f00' }}> Stay Alert. Stay Informed. Trust Only Official Sources.</span></h5>

                                            </div>
                                        </section>


                                        {/* <div className="form">
                                            <input type="hidden" name="_token" defaultValue="qBdaYorbJW9o1kYGJfZIjTsiH1XRzTUs6WVlGvvm" />
                                            <div className="form-row row">
                                                <div className="col-12 col-md-6 form-group">
                                                    <input type="text" className="form-control" name="_name" id="_name" placeholder="Name*" />
                                                    <span className="text-danger font-size-12 fw-normal error-text _name_error" />
                                                </div>
                                                <div className="col-12 col-md-6 form-group">
                                                    <input type="email" className="form-control" name="_email" id="_email" placeholder="Email*" />
                                                    <span className="text-danger font-size-12 fw-normal error-text _email_error" />
                                                </div>
                                                <div className="col-12 col-md-12 form-group">
                                                    <input type="text" className="form-control" name="_mobile" id="_mobile" placeholder="Mobile*" />
                                                    <span className="text-danger font-size-12 fw-normal error-text _mobile_error" />
                                                </div>
                                                <div className="col-12 form-group">
                                                    <input type="text" className="form-control" name="_course_name" id="_course_name" placeholder="Course Name*" />
                                                    <span className="text-danger font-size-12 fw-normal error-text _course_name_error" />
                                                </div>
                                                <div className="col-12 form-group">
                                                    <input type="text" className="form-control" name="_location" id="_location" placeholder="Location*" />
                                                </div>
                                                <div className="col-12 form-group d-flex align-items-start" style={{ marginBottom: 5 }}>
                                                    <input type="checkbox" className="pr-3 mr-3" />
                                                    <label style={{ marginLeft: 3, marginTop: '-10px' }}>I accept the
                                                        T&amp;C of LnG Education.</label>
                                                </div>
                                                <div className="col-12 formFooter">
                                                    <button type="buttom" id="modalsubmit" name="modalsubmit" className="btn btn-yellow popupbtncus">Get Started</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <header className="main-herader">
                <div className="top_header">
                    <div className="container p-0">
                        <div className="row">
                            {/* <div className="col-md-12">
                        <div className="header-topbar-col center767">
                            <ul className="list-inline pull-left address center767">
                                <li><i className="fa fa-envelope" /><Link >info@greenvilleprojects.com</Link></li>
                                <li><i className="fa fa-phone" /><Link >+91 9392121249 </Link></li>
                                <li className="display-n-768"><i className="fa fa-map-marker" /><Link >H.NO 8-2-686/2/6/1/A, GROUND FLOOR, BANJARA HILLS ROAD, 12, 500034, Hyderabad,Telangana</Link></li>
                            </ul>
                            <ul className="socials-icon pull-right center767">
                                <li><Link ><i className="fa fa-facebook-f" /></Link></li>
                                <li><Link ><i className="fa fa-twitter" /></Link></li>
                                <li><Link ><i className="fa fa-google-plus" /></Link></li>
                            </ul>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-default bootsnav" id="navbar-main">
                    <div className="top-search">
                        <div className="container p-0">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-search" /></span>
                                <input type="text" className="form-control" placeholder="Search" />
                                <span className="input-group-addon close-search"><i className="fa fa-bars" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="container p-0">
                        <div className="navbar-header">
                            <button type="button" id='mobileMenu' className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-menu" onClick={() => setOpen(true)}>
                                {Open ? <span className="fa fa-bars" /> : <span className="fa fa-bars" />}
                            </button>
                            <div>
                                <Link to="/"><a className="navbar-brand"><img src="/assets/images/logo/finallogo3.webp" style={{ width: "100px", height: "70px" }} className="logo" alt /></a> </Link>
                                {/* <p>GreenVilleProjects</p> */}
                            </div>
                        </div>
                        <div className={`header collapse  navbar-collapse ${Open ? 'navbar-collapse' : ''}`} id="navbar-menu" >
                            <ul className="nav navbar-nav navbar-right" id="menu" data-in="fadeInUp" data-out="fadeOutDown" >
                                <li className="dropdown active" onClick={() => closeMenu()}>
                                    <Link to="/" >Home</Link>
                                </li>
                                <li className="dropdown megamenu-fw" onClick={() => closeMenu()}>
                                    <Link to="/aboutus" className="">ABOUT US</Link>
                                </li>
                                <li className="dropdown" >
                                    <Link className="dropdown-toggle" data-toggle="dropdown">PROJECTS</Link>
                                    <ul className="dropdown-menu">
                                        {/* <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link to="/" >NEW PROJECTS</Link>
                                        </li>
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link to="/projectdetails/lorven-country" >RUNNING PROJECTS</Link>
                                        </li>
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link   >UPCOMING PROJECTS</Link>
                                        </li> */}
                                        <li className="dropdown" onClick={() => closeMenu()}>
                                            <Link to="/projects" >COMPLETED PROJECTS</Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className="dropdown megamenu-fw" onClick={() => closeMenu()}>
                                    <Link to="/services" className="
                                    ">SERVICES</Link>
                                </li> */}
                                {/* <li className="dropdown" onClick={() => closeMenu()}>
                            <Link to="/layouts" >Layouts</Link>
                        </li> */}
                                <li className="dropdown" onClick={() => closeMenu()}>
                                    <Link to="/blog" >Blog</Link>
                                </li>
                                <li className="dropdown" onClick={() => closeMenu()}>
                                    <Link to="/contactUs" >CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    )
};


export default Header